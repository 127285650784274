import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    var cleaned = ('' + value).replace(/\D/g, '');

    switch (cleaned.length) {
      case 9:
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        break;
      case 10:
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        break;
      case 11:
      case 12:
      case 13:
        var match = cleaned.match(/^(\d{1,3})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        break;
      
      default:
        // code...
        break;
    }
    return null;
  }
}
